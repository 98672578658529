.chicken-mile {
  display: $d-flex;
  flex-direction: column;
  justify-content: center;
  font-family: $National2Regular;

  &_heading {
    align-items: flex-end;

    h4 {
      font-size: 40px;
      line-height: 1;
      font-weight: bold;
      text-transform: uppercase;
      font-family: $National2Condensed;
    }
  }

  .regular {
    background-color: $regular;
    color: $white;

    .lbl-note-light,
    .lbl-point {
      color: $white;
    }
  }

  .bronze {
    background-color: $bronze;
    color: $white;

    .lbl-note-light,
    .lbl-point {
      color: $white;
    }
  }

  .silver {
    background-color: $silver;
    color: $Brand-Black;

    .lbl-note-light,
    .lbl-point {
      color: $Brand-Black;
    }
  }

  .gold {
    background-color: $gold;
    color: $Brand-Black;

    .lbl-note-light,
    .lbl-point {
      color: $Brand-Black;
    }
  }

  .platinum {
    background-color: $platinum;
    color: $Brand-Black;

    .lbl-note-light,
    .lbl-point {
      color: $Brand-Black;
    }
  }

  .loyalty-qr {
    display: $d-flex;
    justify-content: center;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);

    .loyalty-qr-wrap {
      display: block;
      padding: 8px;
      border-radius: 8px;
      background: $white;
    }

    .qr-placeholder {
      width: 192px;
      height: 192px;
    }

    .pnl-refresh {
      display: $d-flex;
      justify-content: center;
      margin: 0;
      line-height: 4rem;
      font-size: 1rem;

      .kfc-button {
        height: 2.75rem;
        padding: 0 2.5rem;
        border: 0;
        border-radius: 1.4rem;
        font-size: 1rem;
        text-align: center;
        white-space: nowrap;
        display: inline-block;
        background: $white;
        color: $Brand-Black;
        cursor: pointer;
        line-height: 1;
        transition: all 0.2s;
      }

      button.kfc-button {
        padding: 1rem 1rem;

        img {
          height: 2rem;

          &.rotate {
            animation: rotation 2s infinite linear;
          }
        }
      }
    }
  }

  .loyalty-status {
    padding: 1rem;

    .loyalty-heading {
      display: $d-flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .show-qr-btn {
    border: 0;
    background: none;
    font-size: 14px;
  }

  .btn-loyalty-details {
    color: inherit;
    border: none;
    background: none;

    &:hover {
      color: $white;
    }

    &:disabled {
      opacity: 0.4;
    }
  }

  .meter {
    height: 2.2rem;
    position: relative;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 25px;
    padding: 1px;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
    margin: 1rem 0;

    > span {
      display: block;
      height: 100%;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      background-color: $white;
      color: $Brand-Red;
      position: relative;
      line-height: 2.2rem;
      font-size: 1.2rem;
      padding-left: 1rem;
      box-sizing: border-box;
      white-space: nowrap;
    }
  }

  .lbl-point {
    font-size: 10px;
    color: $Gray-light;
  }

  .lbl-note-light {
    font-size: 0.8rem;
    color: $Gray-light;
  }

  .lbl-note-dark {
    font-size: 0.8rem;
    color: #bbb;
  }

  h3 {
    font-size: 1rem;
    margin: 0;
    font-weight: $font-weight-bold;
  }

  .loyalty-linkage {
    .linkage-cards {
      display: $d-flex;
      flex-direction: column;

      .linkage-card {
        display: $d-flex;
        align-items: center;
        padding: 0.5rem 1rem;
        gap: 1rem;
        border-bottom: 1px solid $Gray-light;

        img {
          width: 40px;
        }

        .linkage-status {
          font-size: 0.8rem;
          color: $grey;
          margin-left: 0.8rem;
        }

        .linkage-btn {
          margin-left: auto;
          background: none;
          border: 0;
          color: $black;

          &:disabled {
            opacity: 0.4;
          }
        }
      }
    }
  }

  button {
    &:focus {
      text-decoration: none;
    }

    &:focus-visible {
      outline: none;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .kfc-card-form {
    .kfc-link-form-error {
      display: flex;
      color: $Red-Orange;
      margin-bottom: 12px;
      font-size: 14px;
      font-weight: 400;

      &.general-error {
        margin: 0 1rem 1rem;
      }
    }

    p {
      margin: 0 1rem 1rem;
      font-size: 1rem;
    }

    .btn-kfc-card {
      font-weight: 700;
      &:hover {
        color: #202124;
        text-decoration: none;
      }
    }

    .loyalty-fields {
      margin: 1rem;
      .material-input.field {
        display: flex;
        flex-flow: column-reverse;
        margin-bottom: 1em;

        input {
          background: none;
          font-size: 1rem;
          border: 0;
          border-bottom: 1px solid #494949;
          -webkit-appearance: none;
          border-radius: 0;
          cursor: text;
          padding: 0 0 10px;
          font-family: $National2Regular;
        }

        .material-input.field input,
        .material-input.field label {
          transition: all 0.2s;
          touch-action: manipulation;
        }

        .material-input.field input:focus + label,
        .material-input.field input:not(:placeholder-shown) + label {
          -webkit-transform: translate(0) scale(1);
          transform: translate(0) scale(1);
          cursor: pointer;
        }

        label {
          margin: 0 !important;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          color: #494949;
        }
      }
    }

    .loyalty-fields-btns {
      display: flex;
      flex-direction: column;

      .kfc-button {
        height: 2.75rem;
        padding: 0 2.5rem;
        border: 0;
        border-radius: 1.4rem;
        font-size: 1rem;
        text-align: center;
        white-space: nowrap;
        display: inline-block;
        background: #fff;
        color: #202124;
        cursor: pointer;
        line-height: 1;
        transition: all 0.2s;
      }

      .kfc-button-red {
        background-color: #e4002b;
        color: #fff;
      }

      button {
        margin-top: 1rem;
      }

      .kfc-button:disabled {
        background-color: #ccc;
        box-shadow: none;
      }

      .icon-loading {
        width: 100px;
        margin: 0 auto;
      }

      .loading {
        position: relative;
        background-color: #f5f5f5;
        overflow: hidden;
      }
    }
  }

  .loyalty-miles,
  .loyalty-ranking-history,
  .kfc-card-form,
  .kfc-card-details {
    position: relative;

    .btn-back {
      position: absolute;
      left: 0;
      top: 0;
      font-size: 1rem;
      text-decoration: none;
      border: none;
      background: none;
      font-weight: $font-weight-bold;
      height: 50px;
      width: 50px;
      z-index: 10;
      transform: rotate(180deg);
      color: $Brand-Black;
    }

    h2 {
      font-size: 1rem;
      text-align: center;
      margin: 0 0 1rem;
      padding-top: 1rem;
      font-weight: $font-weight-bold;
    }

    .loyalty-mile-details {
      padding: 1rem;
      border-radius: 8px;
      text-align: center;

      .lbl-status {
        font-size: 0.75rem;
      }

      .lbl-points {
        font-size: 3rem;
        font-weight: $font-weight-bold;
        font-family: $National2Condensed;
      }

      .meter {
        height: 10px;
      }

      p {
        margin: 0 1rem 1rem;
        font-size: 1rem;
      }

      .lbl-accumulate-mile {
        display: $d-flex;
        justify-content: space-between;
      }

      .lbl-mileage-link {
        text-align: right;
        margin-top: 1rem;

        a {
          color: inherit;
          text-decoration: none;
          font-size: 12px;
        }
      }
    }

    .loyalty-ranking {
      padding: 1rem;

      .loyalty-heading {
        display: $d-flex;
        justify-content: space-between;

        h3 {
          font-size: 1rem;
          margin: 0;
          font-weight: $font-weight-bold;

          img {
            height: 22px;
            margin-right: 5px;
            vertical-align: bottom;
          }
        }

        button {
          color: $Brand-Black;
          background: none;
          border: 0;
        }
      }
    }

    .loyalty-tabs.nav-tabs {
      .nav-link {
        text-align: center;
        flex-basis: 50%;
        color: $Brand-Black;
        border: 0;
        border-bottom: 3px solid #ddd;
        font-weight: $font-weight-400;
        opacity: 0.3;
        font-size: 1rem;
        text-decoration: none;

        &:hover {
          opacity: 1;
        }
      }
      .nav-link.active {
        border-color: $Brand-Red;
        opacity: 1;
      }
    }

    .tab-content {
      max-height: 500px;
      overflow-y: auto;

      .loyalty-history-item {
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-top: 1px solid #f8f7f5;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;

        .loyalty-history-date {
          label {
            color: #c0c0c0;
            font-size: 10px;
            margin: 0;
            display: inline;
          }
          p {
            font-size: 1rem;
            margin: 10px 0 0;
            line-height: 18px;
          }
        }

        .loyalty-history-point {
          font-size: 1.5rem;
          font-weight: bold;
          margin-top: 1rem;
          font-family: $National2Condensed;
        }
      }

      .no-data {
        font-weight: bold;
        margin-top: 1rem;
        font-family: $National2Condensed;
        color: $Gray;
        display: flex;
        justify-content: center;
      }
    }
  }

  .loyalty-ranking-history {
    .loyalty-ranking {
      .loyalty-heading {
        display: block;

        .loyalty-history-item {
          border: 0;

          h3 {
            line-height: 20px;
            img {
              height: 20px;
            }
          }

          h3 + div {
            line-height: 20px;
          }

          button#btn-viewpastranking {
            color: $Brand-Red;
            margin: 0;
            padding: 0;
          }

          .loyalty-history-point {
            margin-top: 0;
            line-height: 32px;
          }

          &:nth-child(3) {
            .loyalty-history-point {
              font-size: 1.8rem;
            }
          }

          .loyalty-history-rank {
            width: 60px;
            text-align: center;
            margin: 0;
            font-size: 1.5rem;
            font-weight: bold;
            margin-top: 1rem;
            font-family: $National2Condensed;

            > label {
              font-size: 10px;
              color: #bbb;
              padding-left: 3px;
            }

            img {
              height: 36px;
            }
          }
        }
      }
    }

    #list-rankingthismonth {
      .loyalty-history-item {
        .loyalty-history-point {
          margin-top: 0;
          line-height: 44px;
        }
        .loyalty-history-rank {
          width: 60px;
          text-align: center;
          margin: 0;
          font-size: 1.5rem;
          font-weight: bold;
          font-family: $National2Condensed;
          display: flex;
          flex-direction: column;

          p {
            margin: 0;
          }

          > .rank-label {
            font-size: 10px;
            color: #bbb;
            padding-left: 3px;
            margin: 0;
            line-height: normal;
          }

          img {
            height: 36px;
          }
        }
      }
      .loyalty-history-item:first-child {
        .loyalty-history-rank {
          display: block;
          img {
            height: 44px;
          }
        }

        .loyalty-history-point {
          font-size: 2.2rem;
        }
      }

      .loyalty-history-item:nth-child(2) {
        .loyalty-history-rank {
          display: block;
          img {
            height: 38px;
          }
        }

        .loyalty-history-point {
          font-size: 2rem;
        }
      }

      .loyalty-history-item:nth-child(3) {
        .loyalty-history-rank {
          display: block;
          img {
            height: 32px;
          }
        }

        .loyalty-history-point {
          font-size: 1.8rem;
        }
      }
    }
  }

  .lbl-grey {
    color: $grey;
  }

  .btn-link {
    text-decoration: none;
    border: none;
    background: none;
    cursor: pointer;
    color: #202124;
  }

  .kfc-card-details {
    width: 100%;

    &_wrapper {
      padding: 12px;
    }

    .info-wraper {
      padding-bottom: 8px;
    }
    .loader {
      border: 5px solid #f3f3f3; /* Light grey */
      border-top: 5px solid #3498db; /* Blue */
      border-radius: 50%;
      width: 20px;
      height: 20px;
      animation: spin 2s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    .loyalty-fields-btns {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .kfc-unlink-btn {
      width: 80%;
    }
  }
}

.overlay-Loader {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  &::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-top: -0.71428571rem;
    margin-left: -0.71428571rem;
    border-radius: 50%;
    border: 5px solid #fff;
    border-top-color: $Brand-Red;
    -webkit-animation: spinner 0.8s linear infinite;
    animation: spinner 0.8s linear infinite;
    box-shadow: none;
  }

  @keyframes spinner {
    to {
      transform: rotate(1turn);
    }
  }
}

.info-notification {
  &_container {
    height: auto;
    width: 100%;
    border-radius: 3px;
    padding: 15px;
    background: #fff6e3;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    &.greenbg {
      background: #e6f3e6;
    }
  }

  &_text-container {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #202124;
    margin-left: 12px;
    text-align: left;
    margin-top: 6px;
  }
}

.ranking-modal {
  .modal-body {
    background: white !important;
  }

  h2 {
    font-size: 1rem;
    text-align: center;
    margin: 0 0 1rem;
    padding-top: 0.5rem;
    font-weight: $font-weight-bold;
  }

  #ranking-pastranking {
    .loyalty-history-item {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-top: 1px solid #f8f7f5;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      line-height: 27px;

      .loyalty-history-rank {
        font-size: 1.5rem;
        font-weight: bold;
        margin-top: 1rem;
        font-family: $National2Condensed;
      }
    }

    .no-data {
      font-weight: bold;
      margin-top: 1rem;
      font-family: $National2Condensed;
      color: $Gray;
      display: flex;
      justify-content: center;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
